// Styles
import "./index.scss";

// Browser
import "react-app-polyfill/ie11";

// React
import React from "react";
import ReactDOM from "react-dom";

// 3rd Party
import {
  unstable_createMuiStrictModeTheme as createTheme,
  ThemeProvider,
} from "@mui/material/styles";
import { blue, grey, green, red, orange } from "@mui/material/colors";
import { Auth0Provider } from "@auth0/auth0-react";
import TagManager from "react-gtm-module";
import browserUpdate from "browser-update";
import { LicenseInfo } from "@mui/x-data-grid-pro";

// Common
import reportWebVitals from "./reportWebVitals";
import env from "./common/env";
import AuthorizedApolloProvider from "./common/authorized-apollo-provider";

// Our...
import { Router } from "./router/Router";

// Material UI Theme
const theme = createTheme({
  palette: {
    primary: Object.assign(blue, {
      100: "#fecdd3", // loading first color (light)
      300: "#dd90b6", // loading second color
      500: "#003258", // warn
      800: "#f90035",
    }),
    secondary: Object.assign(grey, {
      A200: "#9c9c9c",
    }),
    success: Object.assign(green, {
      light: "#00AD82",
    }),
    error: Object.assign(red, {
      light: "#F44336",
    }),
    warning: Object.assign(orange, {
      light: "#ED6C02",
    }),
  },
  typography: {
    fontFamily: '"Roboto", sans-serif',
  },
});

// Google Tag Manager
if (env.GOOGLE_TAG_MANAGER) {
  TagManager.initialize({
    gtmId: env.GOOGLE_TAG_MANAGER,
    dataLayer: {},
  });
}

// Browser Upgrade
browserUpdate({
  test: window.location.href.indexOf("#bu-test") > -1,
  required: {
    e: -3, // Edge
    f: -3, // Firefox
    i: 0, // Internet Explorer
    o: -3, // Opera
    s: -3, // Safari
    c: -3, // Chrome
  },
  insecure: true,
  unsupported: true,
  api: 2019.05,
});

// Setting MUI license
LicenseInfo.setLicenseKey(env.MUI_LICENSE_KEY);

//
ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      audience={env.AUTH0_AUDIENCE}
      clientId={env.AUTH0_CLIENTID}
      domain={env.AUTH0_DOMAIN}
      redirectUri={window.location.origin + "/dashboard"}
      leeway="10"
      responseType="token id_token"
      scope="openid profile email read:current_user read:appointments create:approvals create:clients create:comments create:exports create:jurisdictions create:letter_templates create:saved_searches create:users delete:clients delete:permits delete:users read:activities read:approvals read:complaints read:clients read:comments read:emails read:exports read:geometry read:identity_documents read:imports read:jobs read:letter_templates read:letters read:listings read:managers read:parcels read:payments read:permits read:rental_units read:revenue_estimates read:audits read:filings read:saved_searches read:analysts read:tasks read:users switch:jurisdictions update:approvals update:calls update:clients update:comments update:jurisdictions update:letter_templates update:letters update:managers update:payments:refund update:permits update:saved_searches update:tasks update:users read:reports:address_identification read:reports:trends read:reports:complaints read:uploads read:invoices update:invoices create:filings update:complaints update:complaints:undelivered read:integrations update:audits update:audits:auditor update:listings:bookings create:letters create:crawlers read:complaints:undelivered read:features read:reports:payments create:payments:manual update:permits:status update:permits:status:admin update:permits:signature update:permits:registrant update:permits:emergency update:permits:extra update:permits:situs_address update:permits:parcel_number update:permits:owner update:permits:manager update:rental_units update:extensions create:settings update:settings read:settings delete:settings read:meta read:esrimap"
    >
      <AuthorizedApolloProvider>
        <ThemeProvider theme={theme}>
          <Router />
        </ThemeProvider>
      </AuthorizedApolloProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
