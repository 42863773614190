import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useQuery, gql } from "@apollo/client";
import {
  Paper,
  Switch,
  FormGroup,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useJsApiLoader,
} from "@react-google-maps/api";
import qs from "qs";
import { LoadingScreen } from "../common/loading";
import env from "../common/env";
import { useIntercom } from "react-use-intercom";
import "../index.scss";

const divStyle = {
  position: "absolute",
  left: "188px",
  height: "35px",
  background: "#f6f7f8",
  marginTop: "10px",
  padding: "5px 15px 3px 15px",
  opacity: 0.8,
};

const spinnerStyle = {
  position: "absolute",
  left: "188px",
  height: "45px",
  marginTop: "10px",
  padding: "5px 15px 3px 15px",
};

const GET_INITIAL = gql`
  query ($jurisdiction_id: ID!, $buffer: Int) {
    geometry(jurisdiction_id: $jurisdiction_id, buffer: $buffer)
    mapsRentalUnits(search: { size: 1 }) {
      total
    }
  }
`;

const GET_LAST_12_MONTHS_RENTAL_UNITS = gql`
  query {
    mapsRentalUnits(search: { active_last_12_months_only: true }) {
      hits {
        address
        parcel_number
        registration_number
        is_compliant
        is_identified
        is_advertised
        is_qualified
        compliance_explanation
        external_property_id
        latitude
        longitude
      }
    }
  }
`;

const GET_RENTAL_UNITS = gql`
  query {
    mapsRentalUnits {
      hits {
        address
        parcel_number
        registration_number
        is_compliant
        is_identified
        is_advertised
        is_qualified
        compliance_explanation
        external_property_id
        latitude
        longitude
      }
    }
  }
`;

function RentalUnitsView() {
  let mapMarkers = [];
  const [pointsInitialized, setPointsInitialized] = useState(false);
  const { user } = useAuth0();
  const [dataLoading, setDataLoading] = useState(true);
  const [gMaps, setGMaps] = useState("");
  const [buffer, setBuffer] = useState(0);
  const [mapOverlays, setMapOverlays] = useState([]);
  const [mapJsonData, setMapJsonData] = useState([]);
  const [mapOverlaysBuffer, setMapOverlaysBuffer] = useState([]);
  const [mapJsonBufferData, setMapJsonBufferData] = useState([]);
  const [jurisdictionId] = useState(
    user["https://api.hostcompliance.com/metadata"].geoid
  );
  const location = useLocation();
  const { active_last_12_months_only } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const { data: initialData, loading: initialLoading } = useQuery(GET_INITIAL, {
    variables: { jurisdiction_id: jurisdictionId, buffer: 0 },
    onCompleted: (data) => {
      let geometry = JSON.parse(JSON.stringify(data.geometry));
      geometry.features[0].id = "borders";
      if (parseInt(buffer) == 0) {
        setMapJsonData(geometry);
      }
      if (parseInt(buffer) == 250) {
        setMapJsonBufferData(geometry);
      }
      drawShape();
    },
  });

  const { loading: initialLoading250 } = useQuery(GET_INITIAL, {
    variables: { jurisdiction_id: jurisdictionId, buffer: 250 },
    onCompleted: (data) => {
      let geometry = JSON.parse(JSON.stringify(data.geometry));
      geometry.features[0].id = "borders_250";
      setMapJsonBufferData(geometry);
      drawShape();
      if (!initialLoading250) setDataLoading(false);
    },
  });

  function removeTownOverlays() {
    for (let i = 0; i < mapOverlays.length; i++) {
      gMaps.data.remove(mapOverlays[i]);
    }
    if (mapOverlaysBuffer) {
      for (let i = 0; i < mapOverlaysBuffer.length; i++) {
        gMaps.data.remove(mapOverlaysBuffer[i]);
      }
    }
  }

  // Categories
  const [categories, setCategories] = useState({
    COMPLIANT: {
      active: true,
      color: "green",
      description: "COMPLIANT w. land use regulations",
    },
    NONCOMPLIANT: {
      active: true,
      color: "red",
      description: "NON-COMPLIANT w. land use regulations",
    },
    UNKNOWN: {
      active: true,
      color: "blue",
      description: "UNKNOWN COMPLIANCE STATUS",
    },
    UNIDENTIFIED: {
      active: true,
      color: "orange",
      description: "NOT YET IDENTIFIED",
    },
  });

  const toggleCategory = (id) => () => {
    categories[id].active = !categories[id].active;
    setCategories(categories);
    processRentalUnits(rentalUnitData.mapsRentalUnits.hits);
  };

  // Rental Units
  const { data: rentalUnitData, loading: rentalUnitLoading } = useQuery(
    active_last_12_months_only
      ? GET_LAST_12_MONTHS_RENTAL_UNITS
      : GET_RENTAL_UNITS,
    {
      skip: initialLoading,
    }
  );
  const [rentalUnits, setRentalUnits] = useState([]);

  const processRentalUnits = useCallback(
    (hits) => {
      const rentalUnitsCopy = JSON.parse(JSON.stringify(hits));

      setRentalUnits(
        rentalUnitsCopy
          .map((item) => {
            if (!item.is_identified && item.is_qualified == true) {
              item.category_id = "UNIDENTIFIED";
            } else if (item.is_compliant === null) {
              item.category_id = "UNKNOWN";
            } else if (!item.is_compliant) {
              item.category_id = "NONCOMPLIANT";
            } else if (item.is_compliant) {
              item.category_id = "COMPLIANT";
            }
            return item;
          })
          .filter(
            (o) =>
              Object.keys(categories)
                .filter((k) => categories[k].active)
                .indexOf(o.category_id) > -1
          )
      );
    },
    [setRentalUnits, categories]
  );

  function processPoints(geometry, callback, thisArg) {
    if (geometry instanceof window.google.maps.LatLng) {
      callback.call(thisArg, geometry);
    } else if (geometry instanceof window.google.maps.Data.Point) {
      callback.call(thisArg, geometry.get());
    } else {
      geometry.getArray().forEach(function (g) {
        processPoints(g, callback, thisArg);
      });
    }
  }

  function drawShape(map) {
    if (!map) map = gMaps;
    const bounds = new window.google.maps.LatLngBounds();
    // now drawing
    if (mapJsonData.features) {
      setMapOverlays(map.data.addGeoJson(mapJsonData));
    }
    if (parseInt(buffer) == 250 && mapJsonBufferData.features) {
      setMapOverlaysBuffer(map.data.addGeoJson(mapJsonBufferData));
    }

    setGMaps(map);
    if (
      map.data &&
      map.data.getFeatureById("borders") &&
      pointsInitialized == false
    ) {
      processPoints(
        map.data.getFeatureById("borders").getGeometry(),
        bounds.extend,
        bounds
      );
      setPointsInitialized(true);
      map.fitBounds(bounds);
    }
    if (mapJsonData.features && mapJsonBufferData.features)
      setDataLoading(false);
  }

  useEffect(() => {
    removeTownOverlays();
    drawShape();
    if (!rentalUnitData) return;
    processRentalUnits(rentalUnitData.mapsRentalUnits.hits);
  }, [
    rentalUnitData,
    rentalUnitLoading,
    processRentalUnits,
    initialData,
    buffer,
  ]);

  // Map
  const { isLoaded: isGoogleMapLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: env.GOOGLE_MAPS_API_KEY,
  });

  const onLoad = useCallback(function callback(map) {
    setGMaps(map);
    removeTownOverlays();
    drawShape(map);
  });

  const onLoadMarker = useCallback(
    function callback(marker) {
      mapMarkers.push(marker);
    },
    [mapMarkers]
  );

  const [infoWindow, setInfoWindow] = useState(null);

  // Intercom disable

  const { shutdown } = useIntercom();
  shutdown();
  //
  return (
    <div>
      {!initialLoading && isGoogleMapLoaded && (
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "100%",
          }}
          id="properties_1"
          zoom={10}
          onLoad={onLoad}
          gestureHandling="greedy"
        >
          {rentalUnits.map((item) => {
            return (
              <Marker
                key={item.external_property_id}
                position={{
                  lat: item.latitude,
                  lng: item.longitude,
                }}
                title={item.external_property_id}
                icon={{
                  scale: 4,
                  fillColor: categories[item.category_id].color,
                  fillOpacity: 1,
                  strokeWeight: 0,
                  url:
                    "https://app.hostcompliance.com/static/imgs/marker-" +
                    categories[item.category_id].color +
                    ".png?5",
                  clickable: true,
                }}
                onMouseOver={() => {
                  const address = item.address ? `${item.address}: ` : "";
                  setInfoWindow({
                    external_property_id: item.external_property_id,
                    description: `${address} ${item.compliance_explanation}`,
                    position: {
                      lat: item.latitude,
                      lng: item.longitude,
                    },
                  });
                }}
                onClick={() =>
                  window.open(
                    `/dashboard/rental-units/${item.external_property_id}`,
                    "_blank"
                  )
                }
                onMouseOut={() => {
                  setInfoWindow(null);
                }}
                onLoad={onLoadMarker}
              />
            );
          })}

          {infoWindow && (
            <InfoWindow
              position={{
                lat: infoWindow.position.lat,
                lng: infoWindow.position.lng,
              }}
              options={{
                pixelOffset: new window.google.maps.Size(0, -12),
                headerDisabled: true,
              }}
            >
              <>
                <span>
                  <strong>{infoWindow.external_property_id}</strong>
                </span>
                <br></br>
                <span className="mapInfoContent">{infoWindow.description}</span>
              </>
            </InfoWindow>
          )}
          <div id="polygon-choices" style={divStyle}>
            <input
              type="radio"
              name="polygons"
              id="show-polygon"
              className="show-polygon"
              data-buffer="0"
              checked={parseInt(buffer) == 0}
              value="0"
              onClick={() => {
                setBuffer(0);
              }}
              onChange={() => {
                setBuffer(0);
              }}
            />
            <label>Show Polygon</label>
            <input
              type="radio"
              name="polygons"
              id="show-polygon-buffer"
              className="show-polygon"
              data-buffer="250"
              value="250"
              checked={parseInt(buffer) == 250}
              onClick={() => {
                setBuffer(250);
              }}
              onChange={() => {
                setBuffer(250);
              }}
            />
            <label>Show Polygon + 250m</label>
          </div>
          {dataLoading && (
            <div style={spinnerStyle}>
              <CircularProgress />
            </div>
          )}
        </GoogleMap>
      )}

      <Paper
        square
        elevation={1}
        style={{
          top: "60px",
          position: "absolute",
          right: "10px",
          padding: "11px",
        }}
      >
        {categories && (
          <FormGroup>
            {Object.keys(categories).map((id) => {
              const item = categories[id];
              return (
                <FormControlLabel
                  key={id}
                  style={{
                    padding: "4px 0",
                  }}
                  control={
                    <Switch
                      size="small"
                      checked={item.active}
                      onChange={toggleCategory(id)}
                    />
                  }
                  label={
                    <>
                      <img
                        src={
                          "https://app.hostcompliance.com/static/imgs/marker-" +
                          item.color +
                          ".png?5"
                        }
                      />
                      {item.description}
                    </>
                  }
                />
              );
            })}
          </FormGroup>
        )}
      </Paper>
    </div>
  );
}

export default withAuthenticationRequired(RentalUnitsView, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: LoadingScreen,
});
