import React, { useState, useEffect } from "react";
import MapESRI from "./mapESRI";
import MapApp from "./map";
import { Box, Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import jwt_decode from "jwt-decode";
import "./MapSwitcher.scss";
import classNames from "classnames";

const MapSwitcher = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [currentMap, setCurrentMap] = useState<string>("MapApp");
  const [scope, setScope] = useState<string>("");
  useEffect(() => {
    async function getScope() {
      const token = await getAccessTokenSilently();
      setScope(jwt_decode<any>(token).scope);
    }
    getScope();
  }, [getAccessTokenSilently]);

  return (
    <>
      {scope.indexOf("read:esrimap") > -1 && (
        <Box className="bottomRightBox">
          <Button
            variant="contained"
            className={classNames(
              "submitMapButton",
              currentMap === "MapApp" ? "highlightMapButton" : ""
            )}
            onClick={() => {
              setCurrentMap("MapApp");
            }}
          >
            Google
          </Button>
          <Button
            variant="contained"
            className={classNames(
              "submitMapButton",
              currentMap === "MapESRI" ? "highlightMapButton" : ""
            )}
            onClick={() => {
              setCurrentMap("MapESRI");
            }}
          >
            Esri
          </Button>
        </Box>
      )}
      {currentMap === "MapApp" ? <MapApp /> : <MapESRI />}
    </>
  );
};

export default MapSwitcher;
