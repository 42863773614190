import React, { useCallback, useEffect, useRef, useState } from "react";
import "@arcgis/core/assets/esri/themes/light/main.css";
import MapView from "@arcgis/core/views/MapView";
import Map from "@arcgis/core/Map";
import esriConfig from "@arcgis/core/config";
import env from "../common/env";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";
import { useQuery, gql } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";
import PopupTemplate from "@arcgis/core/PopupTemplate";
import { FeatureCollection as GeoJSONFeatureCollection } from "geojson";
import {
  Box,
  CircularProgress,
  Paper,
  FormGroup,
  Switch,
  FormControlLabel,
} from "@mui/material";

const GET_INITIAL = gql`
  query ($jurisdiction_id: ID!, $buffer: Int) {
    geometry(jurisdiction_id: $jurisdiction_id, buffer: $buffer)
    mapsRentalUnits(search: { size: 1 }) {
      total
    }
  }
`;
const GET_RENTAL_UNITS = gql`
  query {
    mapsRentalUnits {
      hits {
        address
        parcel_number
        registration_number
        is_compliant
        is_identified
        is_advertised
        is_qualified
        compliance_explanation
        external_property_id
        latitude
        longitude
      }
    }
  }
`;

interface FeatureCollection {
  type: string;
  features: any[];
}

type markerStyleType =
  | "circle"
  | "square"
  | "cross"
  | "x"
  | "diamond"
  | "triangle"
  | "path"
  | undefined;

interface CategorizedFeatures {
  UNIDENTIFIED: FeatureCollection;
  UNKNOWN: FeatureCollection;
  NONCOMPLIANT: FeatureCollection;
  COMPLIANT: FeatureCollection;
}

interface mapInterface {
  color: string;
  shape: markerStyleType;
  title: string;
}

interface mapPointsInterface {
  UNIDENTIFIED: mapInterface;
  UNKNOWN: mapInterface;
  NONCOMPLIANT: mapInterface;
  COMPLIANT: mapInterface;
}

const RentalUnitsMapESRI = () => {
  const mapRef = useRef<HTMLDivElement>(null);
  const mapViewRef = useRef<MapView | null>(null);
  const [isMapViewReady, setMapViewReady] = useState(false);
  const { user } = useAuth0();
  const [jurisdictionId, setJurisdictionId] = useState<string>();
  const [mapJsonData, setMapJsonData] = useState<GeoJSONFeatureCollection>();
  const [propertiesJsonData, setPropertiesJSONData] = useState<
    CategorizedFeatures | any
  >({});
  const propertiesJsonDataRef = useRef<CategorizedFeatures | any>({});
  const [rentalUnitsData, setRentalUnitsData] = useState();
  const { refetch: getInitialData } = useQuery(GET_INITIAL, {
    skip: !jurisdictionId,
    variables: { jurisdiction_id: jurisdictionId, buffer: 0 },
    onCompleted: (data) => {
      let geometry = JSON.parse(JSON.stringify(data.geometry));
      geometry.features[0].id = "borders";
      setMapJsonData(geometry);
    },
  });

  useEffect(() => {
    if (jurisdictionId) {
      getInitialData();
    }
  }, [jurisdictionId]);

  useEffect(() => {
    if (user) {
      setJurisdictionId(user["https://api.hostcompliance.com/metadata"].geoid);
    }
  }, [user]);

  // Categories
  const [categories, setCategories] = useState({
    COMPLIANT: {
      active: true,
      color: "green",
      description: "COMPLIANT w. land use regulations",
    },
    NONCOMPLIANT: {
      active: true,
      color: "red",
      description: "NON-COMPLIANT w. land use regulations",
    },
    UNKNOWN: {
      active: true,
      color: "blue",
      description: "UNKNOWN COMPLIANCE STATUS",
    },
    UNIDENTIFIED: {
      active: true,
      color: "orange",
      description: "NOT YET IDENTIFIED",
    },
  });

  const processRentalUnit = useCallback(
    (hits: any) => {
      let initialPropertiesJSON: CategorizedFeatures = {
        UNIDENTIFIED: {
          type: "FeatureCollection",
          features: [],
        },
        UNKNOWN: {
          type: "FeatureCollection",
          features: [],
        },
        NONCOMPLIANT: {
          type: "FeatureCollection",
          features: [],
        },
        COMPLIANT: {
          type: "FeatureCollection",
          features: [],
        },
      };
      hits.forEach(
        (item: {
          address: any;
          compliance_explanation: any;
          external_property_id: any;
          is_advertised: any;
          is_compliant: null;
          is_identified: any;
          is_qualified: boolean;
          latitude: any;
          longitude: any;
          parcel_number: any;
          registration_number: any;
        }) => {
          // Convert item to GeoJSON feature format if necessary
          let feature = {
            type: "Feature",
            properties: {
              address: item.address,
              compliance_explanation: item.compliance_explanation,
              external_property_id: item.external_property_id,
              is_advertised: item.is_advertised,
              is_compliant: item.is_compliant,
              is_identified: item.is_identified,
              is_qualified: item.is_qualified,
              latitude: item.latitude,
              longitude: item.longitude,
              parcel_number: item.parcel_number,
              registration_number: item.registration_number,
            },
            geometry: {
              type: "Point",
              coordinates: [item.longitude, item.latitude],
            },
          };

          // Categorize the feature based on the conditions provided
          if (!item.is_identified && item.is_qualified === true) {
            initialPropertiesJSON.UNIDENTIFIED.features.push(feature);
          } else if (item.is_compliant === null) {
            initialPropertiesJSON.UNKNOWN.features.push(feature);
          } else if (!item.is_compliant) {
            initialPropertiesJSON.NONCOMPLIANT.features.push(feature);
          } else if (item.is_compliant) {
            initialPropertiesJSON.COMPLIANT.features.push(feature);
          }
        }
      );
      /*added logic to check if it is active here then combining in above condition because there we listings with is_compliant and is_qualified as true and is_identified as false,
    when unidentified was unchecked it was adding property to COMPLIANT which is incorrect and was giving improper results*/
      Object.entries(categories).forEach(([key, value]) => {
        const typedKey = key as keyof CategorizedFeatures;
        if (value.active === false) {
          initialPropertiesJSON[typedKey].features = [];
        }
      });
      setPropertiesJSONData(initialPropertiesJSON);
      propertiesJsonDataRef.current = initialPropertiesJSON;
    },
    [setPropertiesJSONData, categories]
  );

  // Rental Units
  useQuery(GET_RENTAL_UNITS, {
    onCompleted: async (data) => {
      setRentalUnitsData(data.mapsRentalUnits.hits);
      processRentalUnit(data.mapsRentalUnits.hits);
    },
  });

  const getPropertiesLayerForGeoJson = (
    geoJsonData: any,
    color: string,
    style: markerStyleType,
    title: string
  ) => {
    const renderer = new SimpleRenderer({
      symbol: new SimpleMarkerSymbol({
        color: color ?? "#B3B3B3",
        size: 9,
        style: style,
        outline: {
          width: 0.1,
        },
      }),
    });

    // Define a PopupTemplate
    const popupTemplate = new PopupTemplate({
      content: (feature: any) => {
        const attributes = feature.graphic.attributes;

        const externalPropertyId = attributes.external_property_id || "N/A";
        const address = attributes.address || "Address not available";
        const complianceExplanation =
          attributes.compliance_explanation || "Explanation not available";

        const popupContent = document.createElement("div");
        const titleElement = document.createElement("strong");
        titleElement.textContent = externalPropertyId;
        popupContent.appendChild(titleElement);

        const description = document.createElement("div");
        description.innerHTML = `<br>${address}: ${complianceExplanation}`;
        popupContent.appendChild(description);

        return popupContent;
      },
      overwriteActions: true,
      actions: [],
    });

    // Add the PopupTemplate to the GeoJSONLayer configuration
    return new GeoJSONLayer({
      url: URL.createObjectURL(
        new Blob([JSON.stringify(geoJsonData)], { type: "application/json" })
      ),
      title: title,
      renderer: renderer,
      popupTemplate: popupTemplate, // Assign the popupTemplate here
      outFields: ["external_property_id", "address", "compliance_explanation"],
    });
  };

  const getMapLayerForGeoJson = (geoJsonData: any, title: string) => {
    var fillSymbol = new SimpleFillSymbol({
      outline: {
        color: [0, 0, 0, 1],
        width: 2,
      },
      color: [133, 133, 133, 0.5],
    });

    const renderer = new SimpleRenderer({
      symbol: fillSymbol,
    });
    return new GeoJSONLayer({
      url: URL.createObjectURL(
        new Blob([JSON.stringify(geoJsonData)], { type: "application/json" })
      ),
      title: title,
      renderer: renderer,
    });
  };

  const getMiddleFeatureCoordinates = () => {
    const features = mapJsonData?.features;
    if (features && features.length > 0) {
      const middleIndex = Math.floor(features.length / 2);
      const middleFeature = features[middleIndex];
      // Assuming the middle feature is a Point for simplicity. Adjust as needed for other types.
      if (middleFeature.geometry.type === "Polygon") {
        // get the middle coordinates of the polygon
        const polygon = middleFeature.geometry.coordinates[0];
        const middleIndex = Math.floor(polygon.length / 2);
        return polygon[middleIndex];
        // return middleFeature.geometry.coordinates;
      }
    }
    // Return a default center if no features are available or if the middle feature is not a Point.
    return [0, 0]; // Default center coordinates
  };

  useEffect(() => {
    esriConfig.apiKey = env.ARCGIS_MAPS_API_KEY;
    if (mapRef.current) {
      const map = new Map({
        basemap: "streets",
      });
      const mapView = new MapView({
        container: mapRef.current,
        map: map,
        center: getMiddleFeatureCoordinates(),
        zoom: mapJsonData ? 9 : 4,
      });

      mapView.popup.visibleElements = {
        collapseButton: false,
        actionBar: false,
        featureNavigation: false,
        closeButton: false,
        heading: false,
      };

      mapView.popup.dockOptions = {
        buttonEnabled: false,
        position: "auto",
      };

      if (mapJsonData) {
        map.add(getMapLayerForGeoJson(mapJsonData, "mapBorder"));
      }
      mapViewRef.current = mapView;
      setMapViewReady(true);

      mapView.on("pointer-move", (event) => {
        mapView.hitTest(event).then((response) => {
          const results = response.results;

          if (results.length === 0) {
            mapView.popup.close();
            return;
          }

          const graphicResult: any = results.find(
            (result) =>
              (result as any).graphic &&
              (result as any).graphic.layer?.type === "geojson"
          );
          const graphic = graphicResult?.graphic;
          const graphicLayerTitle = graphic?.layer?.title;

          if (
            graphic &&
            graphicLayerTitle &&
            graphicLayerTitle !== "mapBorder"
          ) {
            const mapPoint = mapView.toMap({ x: event.x, y: event.y });
            if (mapPoint) {
              if (!mapView.popup.visible) {
                mapView.popup.open({
                  location: mapPoint,
                  features: [graphic],
                });
              }
            }
          } else {
            if (mapView.popup.visible) {
              mapView.popup.close();
            }
          }
        });
      });

      mapView.on("click", (event) => {
        mapView.hitTest(event).then((response) => {
          const results = response.results;
          const graphicResult: any = results.find(
            (result) =>
              (result as any).graphic &&
              (result as any).graphic.layer?.type === "geojson"
          );
          const graphic = graphicResult?.graphic;

          if (graphic && graphic.attributes?.external_property_id) {
            const external_property_id =
              graphic.attributes.external_property_id;
            window.open(
              `/dashboard/rental-units/${external_property_id}`,
              "_blank"
            );
          }
        });
      });

      return () => {
        if (mapView) {
          mapView.destroy();
        }
      };
    }
  }, [mapJsonData]);

  function removeAllLayersExceptBorder(mapView: MapView) {
    const allLayers = mapView.map.layers.toArray();
    let layersToRemove = allLayers.filter(
      (layer) => layer.title !== "mapBorder"
    );
    layersToRemove.forEach((layer) => {
      mapView.map.remove(layer);
    });
    return Promise.all(layersToRemove);
  }

  useEffect(() => {
    // Update properties data on the map without reinitializing the map
    if (isMapViewReady) {
      if (mapViewRef.current && propertiesJsonData) {
        const mapView = mapViewRef.current;
        if (mapView) {
          removeAllLayersExceptBorder(mapView).then(() => {
            mapView.when(() => {
              mapView.map.add(
                getPropertiesLayerForGeoJson(
                  propertiesJsonData.UNIDENTIFIED,
                  "#eba153",
                  "triangle",
                  "UNIDENTIFIED"
                )
              );
              mapView.map.add(
                getPropertiesLayerForGeoJson(
                  propertiesJsonData.UNKNOWN,
                  "#5462f3",
                  "diamond",
                  "UNKNOWN"
                )
              );
              mapView.map.add(
                getPropertiesLayerForGeoJson(
                  propertiesJsonData.NONCOMPLIANT,
                  "#de5d56",
                  "square",
                  "NONCOMPLIANT"
                )
              );
              mapView.map.add(
                getPropertiesLayerForGeoJson(
                  propertiesJsonData.COMPLIANT,
                  "#71a651",
                  "circle",
                  "COMPLIANT"
                )
              );
            });
          });
        }
      }
    }
  }, [isMapViewReady, propertiesJsonData]);

  function toggleMapupdate(id: string) {
    const categories_item = id as
      | "COMPLIANT"
      | "NONCOMPLIANT"
      | "UNKNOWN"
      | "UNIDENTIFIED";
    const mapView = mapViewRef.current;
    const localPropertiesJSON = propertiesJsonDataRef.current;
    const mapPointsDetails: mapPointsInterface = {
      COMPLIANT: {
        color: "#71a651",
        shape: "circle",
        title: "COMPLIANT",
      },
      NONCOMPLIANT: {
        color: "#de5d56",
        shape: "square",
        title: "NONCOMPLIANT",
      },
      UNKNOWN: {
        color: "#5462f3",
        shape: "diamond",
        title: "UNKNOWN",
      },
      UNIDENTIFIED: {
        color: "#eba153",
        shape: "triangle",
        title: "UNIDENTIFIED",
      },
    };
    if (mapView) {
      if (categories[categories_item].active) {
        mapView.when(() => {
          mapView.map.add(
            getPropertiesLayerForGeoJson(
              localPropertiesJSON[categories_item],
              mapPointsDetails[categories_item].color,
              mapPointsDetails[categories_item].shape,
              mapPointsDetails[categories_item].title
            )
          );
        });
      } else {
        const layerToRemove = mapView.map.layers.find(
          (layer) => layer.title === mapPointsDetails[categories_item].title
        );
        mapView.map.remove(layerToRemove);
      }
    }
  }

  function awaitProcessRentalUnit(): Promise<void> {
    return new Promise((resolve) => {
      processRentalUnit(rentalUnitsData);
      resolve();
    });
  }

  const awaitRur = async () => {
    await awaitProcessRentalUnit();
  };

  const toggleCategory = (id: string) => () => {
    setMapViewReady(false);
    const categories_item = id as
      | "COMPLIANT"
      | "NONCOMPLIANT"
      | "UNKNOWN"
      | "UNIDENTIFIED";
    categories[categories_item].active = !categories[categories_item].active;
    setCategories(categories);
    awaitRur();
    toggleMapupdate(id);
  };

  return (
    <Box style={{ position: "relative", width: "100%", height: "100%" }}>
      {mapJsonData && (
        <Box ref={mapRef} style={{ width: "100%", height: "100%" }} />
      )}
      <Paper
        square
        elevation={1}
        style={{
          top: "60px",
          position: "absolute",
          right: "10px",
          padding: "11px",
        }}
      >
        {categories && (
          <FormGroup>
            {Object.keys(categories).map((id: string) => {
              const categories_item = id as
                | "COMPLIANT"
                | "NONCOMPLIANT"
                | "UNKNOWN"
                | "UNIDENTIFIED";
              const item = categories[categories_item];
              return (
                <FormControlLabel
                  key={id}
                  style={{
                    padding: "4px 0",
                  }}
                  control={
                    <Switch
                      size="small"
                      checked={item.active}
                      onChange={toggleCategory(id)}
                    />
                  }
                  label={
                    <>
                      <img
                        src={
                          "https://app.hostcompliance.com/static/imgs/marker-" +
                          item.color +
                          ".png?5"
                        }
                      />
                      {item.description}
                    </>
                  }
                />
              );
            })}
          </FormGroup>
        )}
      </Paper>
      {!propertiesJsonData ||
        (!mapJsonData && (
          <Box
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ))}
    </Box>
  );
};

export default RentalUnitsMapESRI;
