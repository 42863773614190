import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// 3rd Party
import { useIntercom } from "react-use-intercom";
import { useAuth0 } from "@auth0/auth0-react";

// Common
import AngularPassthru from "../common/angular-passthru";
import { featureFlags } from "../common/feature-flags";

// Our
import DashboardApp from "../dashboard/dashboard";
import KibanaIframe from "../components/KibanaIframe/KibanaIframe";
import DashboardSelector from "../components/DashboardSelector/DashboardSelector";
import EmbedApp from "../embed/embed";
import TasksApp from "../tasks/tasks";
import IntegrationsPanel from "../integrations/IntegrationsPanel";
import { EsriProvider } from "../integrations/esri/context/EsriContext";
import EsriPage from "../integrations/esri/EsriPage";
import Errors from "../common/errors";
import QuestionnairesApp from "../questionnaires/questionnaires";
import env from "../common/env";
import DownloadExport from "../components/DownloadExport/DownloadExport";
import MapSwitcher from "../map/MapSwitcher";

const Routes = ({ jurisdictionClient, jurisdictionData }) => {
  const { update } = useIntercom();

  const { user } = useAuth0();

  // Booting intercom once user is logged in
  const updateHandler = () =>
    update({
      name: user.name,
      email: user.email,
      createdAt: user["https://api.hostcompliance.com/created_at"],
      geoid: user["https://api.hostcompliance.com/geoid"],
      is_kibana_enabled:
        user &&
        user["https://api.hostcompliance.com/metadata"] &&
        user["https://api.hostcompliance.com/metadata"]?.kibana_enabled
          ? "Y"
          : "N",
      is_ram_enabled:
        user &&
        user["https://api.hostcompliance.com/metadata"] &&
        user["https://api.hostcompliance.com/metadata"]._groups &&
        user["https://api.hostcompliance.com/metadata"]?._groups.includes(
          "rental_activity_monitoring"
        )
          ? "Y"
          : "N",
      is_editable_fields_enabled:
        user &&
        user["https://api.hostcompliance.com/metadata"] &&
        user["https://api.hostcompliance.com/metadata"]._groups &&
        user["https://api.hostcompliance.com/metadata"]?._groups.includes(
          "registration_tax_collection_systems"
        )
          ? "Y"
          : "N",
      is_ram_lite_enabled:
        user &&
        user["https://api.hostcompliance.com/metadata"] &&
        user["https://api.hostcompliance.com/metadata"]._groups &&
        user["https://api.hostcompliance.com/metadata"]?._groups.includes(
          "rental_activity_monitoring_lite"
        )
          ? "Y"
          : "N",
    });

  useEffect(() => {
    if (user && env.ENVIRONMENT === "production") updateHandler();
  }, [user]);

  return (
    <Switch>
      <Route
        path="/dashboard"
        component={() => (
          <DashboardApp
            jurisdictionClient={jurisdictionClient}
            jurisdictionData={jurisdictionData}
          />
        )}
      />
      <Route path="/map" component={MapSwitcher} />
      {/* <Route path="/map-esri" component={MapESRI} /> */}
      <Route path="/loadKibana" component={KibanaIframe} />
      <Route path="/dashboard-options" component={DashboardSelector} />
      <Route path="/embed" component={EmbedApp} />
      <Route path="/tasks" component={TasksApp} />
      <Route path="/download" component={DownloadExport} />
      <Route exact path="/integrations" component={IntegrationsPanel} />
      <Route
        path="/integrations/esri"
        render={() => (
          <EsriProvider>
            <EsriPage />
          </EsriProvider>
        )}
      />
      <Route path="/errors/:code" component={Errors} />
      <Route
        path="/:questionnaireId(complaints|tips|evidence)/:questionId?"
        component={QuestionnairesApp}
      />
      <Route
        path="/:jurisdictionId/:questionnaireId(complaints|auth|registrations|registrations-revisions|registrations-filings|managers-registration|managers|permit-revisions|approval-revisions|audits-revisions|platforms)/:questionId?"
        component={QuestionnairesApp}
      />
      <Route
        path="/:jurisdictionId/:questionnaireId(remit|permit-registration)/:questionId?"
        component={AngularPassthru}
      />
      <Route
        exact
        path="/sign-in"
        render={() => {
          const userFeatures = featureFlags.get();
          featureFlags.set(userFeatures);
          return <Redirect to="/dashboard" />;
        }}
      />
      <Route exact path="/">
        <Redirect to="/dashboard" />
      </Route>
      <Route>
        <Redirect to="/errors/404" />
      </Route>
    </Switch>
  );
};

export default Routes;
