import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Box,
  Typography,
} from "@mui/material";
import parser from "./parser";

function View({ question, context }) {
  const { control } = useForm();
  const [invoice, setInvoice] = useState();
  const [formErrors, setFormErrors] = useState({ amount: [], description: [] });
  const locale = question.properties.locale; // Locale data
  const input = parser.parse(question.input, context); // Input data

  useEffect(() => {
    const tempInvoice = context.pick(input.invoice); // Invoice data
    if (tempInvoice) {
      tempInvoice.lineItems.forEach((item) => {
        if (item.active === undefined) {
          item.active = true;
        }
        if (item.mutable === undefined) {
          item.mutable = {};
        }
        if (item.mutable) {
          item.mutable.default = item.amount;
          if (item.mutable.max === 0) {
            item.mutable.min = 0 - tempInvoice.subtotal;
          }
        }
      });

      if (tempInvoice.status === "PAID") {
        input.mutable = false;
      }
      setInvoice(tempInvoice);
    }
  }, []);

  const extractContentInsideParentheses = (text) => {
    const startIndex = text.indexOf("(");
    const endIndex = text.lastIndexOf(")");
    return startIndex > -1 && endIndex > startIndex
      ? text.substring(startIndex + 1, endIndex)
      : "";
  };

  const toggleLineItem = (item, index) => {
    const newLineItems = [...invoice.lineItems];
    if (item.mutable.description) {
      delete newLineItems[index].description;
    }

    if (item.amount > 0) {
      newLineItems[index].amount = 0;
      newLineItems[index].mutable.expand = false;
    } else {
      newLineItems[index].amount = item.mutable.default;
    }

    setInvoice((prevInvoice) => {
      return { ...prevInvoice, lineItems: newLineItems };
    });
    context.assign({
      value: invoice,
    });
  };

  const handleAmountChange = (event, index) => {
    const newFormErrors = { ...formErrors };
    const value = event.target.value;
    const minValue = invoice.lineItems[index].mutable?.min;
    const maxValue = invoice.lineItems[index].mutable?.max;

    setInvoice((prevInvoice) => {
      const newLineItems = [...prevInvoice.lineItems];
      newLineItems[index].amount = value;
      return { ...prevInvoice, lineItems: newLineItems };
    });

    if (value === "") {
      newFormErrors.amount[index] = locale.validation_required;
    } else if (minValue !== null && value < minValue) {
      newFormErrors.amount[index] = locale.validation_minimum + " " + minValue;
    } else if (maxValue !== null && value > maxValue) {
      newFormErrors.amount[index] = locale.validation_maximum + " " + maxValue;
    } else {
      newFormErrors.amount[index] = "";
    }

    setFormErrors(newFormErrors);
    context.assign({
      value: invoice,
    });
  };

  const handleDescriptionChange = (event, index) => {
    const newFormErrors = { ...formErrors };
    const value = event.target.value;

    setInvoice((prevInvoice) => {
      const newLineItems = [...prevInvoice.lineItems];
      newLineItems[index].description = value;
      return { ...prevInvoice, lineItems: newLineItems };
    });

    if (value === "") {
      newFormErrors.description[index] = locale.validation_required;
    } else {
      newFormErrors.description[index] = "";
    }

    setFormErrors(newFormErrors);
    context.assign({
      value: invoice,
    });
  };

  return (
    <div dir={locale.dir} className="inputs inputs-invoice">
      {invoice && (
        <Table>
          <TableBody>
            {invoice.lineItems.map((item, index) => (
              <TableRow
                key={index}
                style={{
                  display:
                    (!input.mutable && item.active) || input.mutable
                      ? ""
                      : "none",
                }}
              >
                {input.mutable && (
                  <TableCell colSpan={1} className="checkbox checkbox-cell">
                    <Checkbox
                      checked={item.active}
                      disabled={!item.mutable?.active}
                      className={!item.mutable?.active ? "disabled" : ""}
                      onClick={() => toggleLineItem(item, index)}
                      id={`ref-invoice-${item.type}-active`}
                    />
                  </TableCell>
                )}
                <TableCell colSpan={2} className="description-cell">
                  {item.mutable?.description ? (
                    <Controller
                      name={`lineItems[${index}].description`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={locale[item.type]}
                          fullWidth
                          disabled={!item.active}
                          required={item.active}
                          variant="outlined"
                          onChange={(event) =>
                            handleDescriptionChange(event, index)
                          }
                          value={item.description || ""}
                          error={
                            !!(
                              formErrors.description[index] &&
                              formErrors.description[index] !== ""
                            )
                          }
                          helperText={formErrors.description[index]}
                        />
                      )}
                    />
                  ) : (
                    <>
                      {item.description ? (
                        item.description.includes("(") ? (
                          <>
                            {item.description.split("(")[0]}
                            <small>
                              <br />
                              {extractContentInsideParentheses(
                                item.description
                              )}
                            </small>
                          </>
                        ) : (
                          item.description
                        )
                      ) : (
                        locale[item.type]
                      )}
                    </>
                  )}
                </TableCell>
                <TableCell className="amount-cell">
                  {item.mutable?.amount ? (
                    <Box className="amount-box">
                      <Typography className="dollar">$</Typography>
                      <Controller
                        name={`lineItems[${index}].amount`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={"Amount"}
                            fullWidth
                            disabled={!item.active}
                            required={item.active}
                            variant="outlined"
                            onChange={(event) =>
                              handleAmountChange(event, index)
                            }
                            value={item.amount}
                            error={
                              !!(
                                formErrors.amount[index] &&
                                formErrors.amount[index] !== ""
                              )
                            }
                            helperText={formErrors.amount[index]}
                            inputProps={{
                              type: "number",
                              style: { fontSize: "130%" }, // Change this to the desired font size
                            }}
                          />
                        )}
                      />
                    </Box>
                  ) : (
                    `$${item.amount.toFixed(2)}`
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell
                colSpan={input.mutable ? 3 : 2}
                className="sub-total-label"
              >
                {locale.subtotal}
              </TableCell>
              <TableCell className="sub-total-amount">
                <strong>
                  $
                  {invoice.lineItems
                    .reduce(
                      (sum, item) =>
                        item.amount === "" ? 0 : Number(item.amount) + sum,
                      0
                    )
                    .toFixed(2)}
                </strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </div>
  );
}

export default View;
